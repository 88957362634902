<template>
    <div
        v-if="item.dismissed"
        class="alert-info">
        <styled-tooltip
            nudge-right="-170"
            nudge-top="5"
            :max-width="400"
            position="left-far">
            <template #content>
                <div class="dismissed">
                    <div class="dismissed__left">
                        <div>Dismissed by:</div>
                        <div>Date Dismissed:</div>
                        <div>Reason:</div>
                    </div>
                    <div class="dismissed__right">
                        <div>{{ item.dismissed_by }}</div>
                        <div>{{ dateDismissed }}</div>
                        <div>{{ reason }}</div>
                    </div>
                </div>
            </template>
            <icon
                name="list"
                size="18" />
        </styled-tooltip>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledTooltip from '@/components/globals/StyledTooltip';
import { format } from 'date-fns';

export default {
    components: {
        Icon,
        StyledTooltip
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        reason() {
            if (this.item.dismissed_comment) {
                return this.item.dismissed_comment;
            } else {
                return this.item.dismissed_reason;
            }
        },
        dateDismissed() {
            return format(this.item.dismissed_date, 'MM/DD/YYYY');
        }
    }
};
</script>

<style lang="scss" scoped>
.alert-info {
    cursor: pointer;
}
.dismissed {
    display: flex;
    justify-content: space-between;
    width: 380px;
    &__left {
        width: 100px;
        text-align: right;
        div {
            margin-bottom: 3px;
        }
    }
    &__right {
        width: 265px;
        div {
            margin-bottom: 3px;
        }
    }
}
</style>